import React from "react";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { makeStyles } from "@material-ui/core/styles";
import { API_URL } from "_services/api.url.back.img";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import "./styles.css";
import { Link } from "react-router-dom";
import { getSlug } from "helpers/getSlug";

const breakPoints = [
    { width: 1, itemsToShow: 1 }, 
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 5 },
    { width: 1200, itemsToShow: 5 },
];

const useStyles = makeStyles((styles) => ({
    textoDesplazable:{textOverflow:'ellipsis', whiteSpace:'nowrap', fontSize:'11px'},
    container: {
        width: "100%",
    },
    imgDiv: {
        width: "95%",
        display: "inline-block",
        border: '1px solid #efefef',
        borderRadius: '10px',
        padding: '20px 10px',
        "&:hover": {
            cursor: "pointer",
        },
        [styles.breakpoints.down("sm")]: {
            width: "80%",
        },
    },
    image: {
        width: "100%",
    },
    // carousels
    carouselTitle: {
        fontFamily: "'Centrale Sans Medium'",
        fontWeight: 600,
        color: "#0b3196",
        marginTop: 10,
    },
    carouselDivider: {
        border: "2px solid #40DF00",
        backgroundColor: "#40DF00",
        marginTop: 8,
        borderRadius: 20,
    },
    carouselContent: {
        marginTop: 6,
    },
    imgTrusty: {
        width: "90%",
    },
}));

const CarruselComponent = ({ productsData, isMobile }) => {
    const history = useHistory();
    const classes = useStyles();

    const Item = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 235px;
        width: 100%;
        background-color: transparent;
        color: #fff;
        font-size: 4em;
    `;

    const goTo = (link) => {
        if (link) {
            history.push(`/producto/detalle/${getSlug(link.prod_nombre)}/${getSlug(link.prod_nombre_extranjero)}?prod=${link.productoPadreId}&sku=${link.prod_producto_id}`)

        }
    };

    return (
        <Box>
            <div className="wrapper">
                <div className={classes.container}>
                   <Link to='/promociones_productos'><Typography
                        variant="h5"
                        sx={{
                            fontWeight: 600,
                            color: "#0b3196",
                            paddingTop: 1,
                            paddingBottom: 2,
                            fontFamily: "Centrale Sans Medium",
                        }}
                    >
                        Próximamente
                    </Typography></Link>
                    <div className={classes.carouselContent}>
                        <div className="App">
                            <Carousel
                                breakPoints={breakPoints} 
                                enableAutoPlay={true}
                                autoPlaySpeed={5000}
                                showArrows={isMobile}
                            >
                            {productsData?.productsAll?.map((item, index) => (
                                <div
                                    key={`carusel-item-id-${index}`}
                                    className={classes.imgDiv}
                                    onClick={() => {
                                        goTo(item);
                                    }}
                                >
                                    <Item key={item.Id}>
                                        <img
                                            key={item.Id}
                                            className={classes.imgTrusty}
                                            src={item.ImagenPortada
                                                }
                                        />
                                    </Item>
                                    <Box>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontWeight: 500,
                                                color: "#0b3196",
                                                fontFamily: "Centrale Sans Medium",
                                            }}
                                            noWrap
                                        >
                                            {item.Nombre}
                                        </Typography>
                                        <Typography
                                            variant="button"
                                            sx={{
                                                fontWeight: 500,
                                                color: "#40df00",
                                                fontFamily: "Centrale Sans Medium",
                                            }}
                                            className={classes.textoDesplazable}
                                        >
                                            {`SKU ${item.NumeroParteFabricante}`}
                                        </Typography>
                                    </Box>
                                </div>
                            ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default CarruselComponent;