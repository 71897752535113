export const getSlug = (text) => {
    return text
        .toLowerCase()
        .normalize("NFD") // Elimina tildes y caracteres especiales
        .replace(/[\u0300-\u036f]/g, "") // Remueve diacríticos
        .replace(/[^a-z0-9\s-]/g, "") // Elimina caracteres especiales excepto espacios y guiones
        .trim()
        .replace(/\s+/g, "-") // Reemplaza espacios por guiones
        .replace(/-+/g, "-"); // Elimina guiones repetidos
};
