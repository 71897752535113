import { Helmet } from "react-helmet";
import React from "react";
export const HeadPage = ({
  articule = "website",
  urlImg = "",
  urlPage = "",
  title = "Dielsa",
  description = "",
  urlAlternate = "",
  urlCanonical = "",
  keywords = "",
}) => {
  return (
    <Helmet>
      <title>{title} - Dielsa</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="Dielsa" />

      {/* Open Graph (Facebook, LinkedIn) */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={urlImg} />
      <meta property="og:url" content={urlPage} />
      <meta property="og:type" content={articule} />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:site_name" content="Dielsa" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={urlImg} />

      {/* SEO Links */}
      <link rel="alternate" href={urlAlternate} hrefLang="es" />
      <link rel="canonical" href={urlCanonical} />
    </Helmet>
  );
};
