import React, { useState, useEffect, Suspense } from "react";
import { Route, Router, Switch, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { createBrowserHistory } from "history";

//Rutas
import HomeViewNew from "views/Home/HomeViewNew";
import ProductView from "views/Products/ProductView";

import LayoutProfile from "components/LayoutProfile/LayoutProfile";
import ProfileDashboard from "views/Profile/ProfileContents/ProfileDashboard";
import ProfileAddress from "views/Profile/ProfileContents/ProfileAddress";
import ProfileAccesos from "views/Profile/ProfileContents/ProfileAccesos";
import ProfileBills from "views/Profile/ProfileContents/ProfileBills";
import ProfileOrders from "views/Profile/ProfileContents/ProfileOrders";

//Cotizaciones
import DetailCotizacion from "views/Profile/ProfileContents/Cotizacion/DetailCotizacion";
import EditarCotizacion from "views/Profile/ProfileContents/Cotizacion/EditarCotizacion";
import ProfileCotizaciones from "views/Profile/ProfileContents/ProfileCotizaciones";
import QuoteView from "views/Profile/ProfileContents/Cotizacion/QuoteView";

//Proyectos
import ProfileProjects from "views/Profile/ProfileContents/ProfileProjects";
import DetailProjects from "views/Profile/ProfileContents/Projects/DetailProjects";

//favoritos
import ProfileWishList from "views/Profile/ProfileContents/ProfileWishList";

import QuickSearchView from "views/Search/QuickSearchView";
/**Alertas del sistema */
import { AlertDefault } from "ui/alertDefault";
import { AlertForm } from "ui/alertForm";

//New routes sin lazy
import ProductDataSheetView from "views/Products/ProductDataSheetView";
import FaqView from "views/Faq/FaqView";
import CheckoutIndex from "views/Checkout/CheckoutIndex";
import CheckoutConekta from "views/Checkout/CheckoutConekta";
import ContactView from "views/Login/ContactView";
import CreditRequest from "views/Institutional/CreditRequest";
import AdvancedSearchForm from "views/Search/AdvancedSearchForm";
import AdvancedSearch from "views/Search/AdvancedSearch";
import QuickShop from "views/Search/QuickShop";
import Request from "views/Quotes/Request";
import CategoryView from "views/Categories/CategoryView.js";
import InstitutionalView from "views/Institutional/InstitutionalView";
import HeaderNew from "views/Header/HeaderNew";
import HeaderMobileNew from "views/Header/HeaderMobileNew";
import FooterNew from "views/Footers/FooterNew";
import useCheckMobileScreen from "components/Mobile/Mobile.js";
import { checkoutService } from "_services/checkout.service";
import { homeServices } from "_services/home.services";
import { API_URL } from "_services/api.url.back.img";
import LastProductsView from "views/LastProductsView/LastProductsView";
import ProductsGeneric from "views/ProductsGeneric/ProductsGeneric";
import { accountService } from "services/account/account.service";
import { SignIn } from "views/SignIn";
import { RegisterUser } from "views/RegisterUser";
import { SignUp } from "views/SignUp";
import { Recovery } from "views/Recovery";
import { RegisterBusiness } from "views/RegisterBusiness";

import { Active } from "components/active";

/**Filtros */
import { Filters } from "views/filters";

var hist = createBrowserHistory();

export const Routes = (props) => {
  const {
    carritoItems,
    setCarritoItems,
    openAlert,
    setOpenAlert,
    openAlertF,
    setOpenAlertF,
    setDataQuote,
    setLogoURL,
    isLogged,
  } = useAuth();

  let isMobile = useCheckMobileScreen();
  // const [carritoItems, setCarritoItems] = useState(null);
  const [redirectErrorPage, setRedirectErrorPage] = useState(false);
  const [divisa, setDivisa] = useState("mxn");
  const history = useHistory();

  const routesNames = [
    "login",
    "recovery",
    "register",
    "sign-up",
    "register-user",
    "contact",
    "creditrequest",
    "myprofile",
    "product",
    "category",
    "categorias",
    "search",
    "advancedSearchForm",
    "advancedSearch",
    "quickShop",
    "institucional",
    "faq",
    "quotes",
    "checkout",
    "coneckta",
    "",
    "ficha",
    "checkoutQuotes",
    "cotizaciones",
    "promociones_productos",
    "filters",
  ];

  const getCarrito = async () => {
    if (JSON.parse(localStorage.getItem("user"))) {
      let _user = JSON.parse(localStorage.getItem("user"));

      console.log(_user);
      if (_user != null && _user != undefined) {
        let _cart = await checkoutService.createOrGetCartByClientId(
          _user.usuario.snu_sn_socio_de_negocio_id
        );

        const totalProducts =
          _cart.data.cdc_carrito_de_compra_id.productos.length;

        // setCarritoItemsPortal(totalProducts);
        setCarritoItems(totalProducts);
      }
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user == null) setCarritoItems(0);

      const localCart = JSON.parse(localStorage.getItem("myCart"));
      const totalItems = localCart ? localCart.length : 0;
      setCarritoItems(totalItems);
    }
  };

  const getLogo = async () => {
    let _l = await homeServices.loadSection("LOGO_HOME");
    if (_l.archivos_main[0] !== undefined) {
      setLogoURL(
        API_URL + _l.archivos_main[0].adi_ruta_archivo.replace("./public", "")
      );
    }
  };

  useEffect(() => {
    getCarrito();
  }, [isLogged]);

  useEffect(() => {
    getCarrito();
    getLogo();
  }, []);

  useEffect(() => {
    //window.alert('hol:'+ hist.location.pathname)
    if (hist.action === "POP" && !hist.location.key) {
      let routeFind = routesNames.find(
        (rname) => rname === hist.location.pathname.split("/")[1]
      );
      // window.alert('h')
      if (routeFind != "checkout") {
        // window.alert('hola quitamos el checkout')
        setDataQuote({
          idCotizacion: 0,
          idSocioNegocio: 0,
          productos: [],
        });
      }
      

      if (routeFind === undefined) {
        setRedirectErrorPage(true);
      }
    }
 
  }, [hist.location.pathname]);

  return (
    <Router history={hist}>
      {isMobile ? (
        <HeaderMobileNew
          carritoItems={carritoItems}
          setCarritoItems={setCarritoItems}
          getCarrito={getCarrito}
        />
      ) : (
        <HeaderNew carritoItems={carritoItems} getCarrito={getCarrito} />
      )}
      <Switch>
        <Suspense fallback={<div>Loading...</div>}>
          <AlertDefault openAlertF={openAlertF} setOpenAlertF={setOpenAlertF} />
          <AlertForm openAlert={openAlert} setOpenAlert={setOpenAlert} />
          <Active />
          <Route
            path="/contact"
            render={(props) => <ContactView {...props} />}
          />
          {/* INICIO DE SESION */}
          <Route path="/login" render={(props) => <SignIn {...props} />} />
          <Route
            path="/register-user"
            render={(props) => <RegisterUser {...props} />}
          />
          <Route path="/sign-up" render={(props) => <SignUp {...props} />} />
          <Route path="/recovery" render={(props) => <Recovery {...props} />} />
          <Route
            path="/register"
            render={(props) => <RegisterBusiness {...props} />}
          />

          <Route
            path="/ultimos_productos"
            render={(props) => <LastProductsView {...props} />}
          />
          <Route
            path="/promociones_productos"
            component={(props) => <ProductsGeneric {...props} />}
          />
          <Route
            path="/creditrequest"
            render={(props) => <CreditRequest {...props} />}
          />
          <Route
            path="/myprofile"
            render={(props) => (
              <LayoutProfile setCarritoItems={setCarritoItems}>
                <Route
                  path="/myprofile/perfil"
                  exact
                  component={ProfileDashboard}
                />
                <Route
                  path="/myprofile/perfil/direcciones"
                  exact
                  component={ProfileAddress}
                />
                <Route
                  path="/myprofile/perfil/usuarios"
                  exact
                  component={ProfileAccesos}
                />
                <Route path="/myprofile/facturas" component={ProfileBills} />
                <Route path="/myprofile/pedidos" component={ProfileOrders} />
                <Route
                  path="/myprofile/cotizaciones/editar/:id"
                  exact
                  component={EditarCotizacion}
                />
                <Route
                  path="/myprofile/cotizaciones"
                  exact
                  render={(props) => <ProfileCotizaciones {...props} />}
                />
                <Route
                  path="/myprofile/cotizaciones/detail/:id"
                  exact
                  component={DetailCotizacion}
                />
                <Route
                  path="/myprofile/favoritos"
                  render={(props) => <ProfileWishList {...props} />}
                />
                <Route
                  path="/myprofile/projects/detail/:id"
                  component={DetailProjects}
                />
                <Route
                  path="/myprofile/proyectos"
                  render={(props) => <ProfileProjects {...props} />}
                />
              </LayoutProfile>
            )}
          />
          {/* <Route path="/product" component={ProductView} /> */}
          {/* <Route
            path="/product"
            render={(props) => <ProductView {...props} divisa={divisa} />}
          /> */}
           <Route
            path="/producto/detalle/:name/:sku"
            render={(props) => <ProductView {...props} divisa={divisa} />}
          />


          <Route
            path="/ficha/:name/:position"
            render={(props) => <ProductDataSheetView />}
          />
          {/* <Route
            path="/category"
            render={(props) => <CategoryView {...props} />}
          /> */}

          <Route path="/filters" render={(props) => <Filters {...props} />} />
          {/* <Route
            path="/categorias/:name"
            exact
            render={(props) => <CategoryView {...props} />}
          /> */}
          {/* <Route path="/search" component={QuickSearchView} /> */}
          <Route
            path="/search/:search"
            render={(props) => <QuickSearchView {...props} />}
          />
          <Route path="/advancedSearchForm" component={AdvancedSearchForm} />
          {/* <Route path="/advancedSearch" component={AdvancedSearch} /> */}
          <Route
            path="/advancedSearch"
            render={(props) => <AdvancedSearch {...props} />}
          />

          <Route
            path="/quickShop"
            render={(props) => <QuickShop {...props} divisa={divisa} />}
          />

          {/* <Route
            path="/institucional"
            component={lazy(() =>
              import("views/Institutional/InstitutionalView")
            )}
          /> */}

          <Route
            path="/institucional/:name"
            exact
            render={(props) => <InstitutionalView {...props} />}
          />

          <Route path="/faq" render={(props) => <FaqView {...props} />} />

          <Route
            path="/quotes"
            render={(props) => <Request {...props} getCarrito={getCarrito} />}
          />

          <Route
            path="/checkout"
            render={(props) => <CheckoutIndex {...props} />}
          />

          <Route
            path="/conekta"
            render={(props) => <CheckoutConekta {...props} />}
          />

          <Route
            exact
            path="/"
            render={(props) => <HomeViewNew {...props} />}
          />

          <Route
            path="/cotizaciones/:id"
            render={(props) => <QuoteView {...props} />}
          />

          {redirectErrorPage && <Redirect to={`/institucional/Error%20404`} />}
        </Suspense>
      </Switch>
      <footer style={{ marginTop: "1rem" }}>
        <FooterNew />
      </footer>
    </Router>
  );
};
