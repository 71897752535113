import React, { useEffect, useState, createContext } from "react";
import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { homeServices } from "_services/home.services";
import { userService } from "_services/user.service";
import { categoryServices } from "_services/category.services";
import { useAuth } from "hooks/useAuth";
import useCheckMobileScreen from "components/Mobile/Mobile";
import globalDataHome from "./globalDataHome";
import { API_URL } from "_services/api.url.back.img";
import { Loader } from "ui/loader";
import { CategoriesMenu } from "components";
import SectionHomeMainBannerNew from "./Sections/SectionHomeMainBannerNew/SectionHomeMainBannerNew";
import SectionHomeMidCarousel from "./Sections/SectionHomeMidCarousel/SectionHomeMidCarousel";
import SectionHomeMidBanners from "./Sections/SectionHomeMidBanners/SectionHomeMidBanners.js";
import SectionSomosMayoristas from "./Sections/SectionSomosMayoristas/SectionSomosMayoristas";
import SectionSomosMayoristasMob from "./Sections/SectionSomosMayoristas/SectionSomosMayoristasMob";
import { CollectionComponent, LastExistenceComponent, CarruselComponent } from "components";
import { Link } from "react-router-dom";
const useStyles = makeStyles((styles) => ({
    menuContainer: {
        ["@media (max-width: 1080px)"]: {
            display: "none", 
        },
    },
}));

let globalData = createContext(globalDataHome);

const HomeViewNew = () => {
    const { setOpenAlert, getUserData} = useAuth();
    let dataUser = getUserData()
    const classes = useStyles();
    const isMobile = useCheckMobileScreen();
    const [anchoTotalGrid, setAnchoTotalGrid] = useState(0);
    const [categorysArray, setCategorysArray] = useState ([]);
    const [subCatsDefault, setSubCatsDefault] = useState([]);
    const [contadores, setContadores] = useState([]);
    const [collectionDataFirts, setCollectionDataFirts] = useState({});
    const [collectionDataSecond, setCollectionDataSecond] = useState({});
    const [collectionDataThird, setCollectionDataThird] = useState({});
    const [productsData, setProductsData] = useState({});

    const [emailNews, setEmailNews] = useState('');

    const getMainSection = async () => {
        let _ms = await homeServices.loadSection(isMobile ? "MAIN_SLIDER_MOVIL" : "MAIN_SLIDER");
        let _temp_a = [];
        for (let i = 0; i < _ms.archivos_main.length; i++) {
          let _a = {
            id: _ms.archivos_main[i].adi_archivo_de_inicio_id,
            img:
              API_URL +
              _ms.archivos_main[i].adi_ruta_archivo.replace("./public", ""),
            link: _ms.archivos_main[i].adi_url,
          };
          _temp_a.push(_a);
        }
        globalData._currentValue.mainBanners.banners = _temp_a;
        let _mbt = await homeServices.loadSection("MAIN_BANNER_TOP");
        let _a1 = {
          img:
            _mbt.archivos_main[0] !== undefined
              ? API_URL +
                _mbt.archivos_main[0].adi_ruta_archivo.replace("./public", "")
              : "",
          link:
            _mbt.archivos_main[0] !== undefined
              ? _mbt.archivos_main[0].adi_url
              : "",
        };
        globalData._currentValue.mainBanners.side1 = _a1;
        let _mbb = await homeServices.loadSection("MAIN_BANNER_BOTTOM");
        let _a2 = {
          img:
            _mbb.archivos_main[0] !== undefined
              ? API_URL +
                _mbb.archivos_main[0].adi_ruta_archivo.replace("./public", "")
              : "",
          link:
            _mbb.archivos_main[0] !== undefined
              ? _mbb.archivos_main[0].adi_url
              : "",
        };
        globalData._currentValue.mainBanners.side2 = _a2;
    };

    const registerNewsletter = async (event) => {
        event.preventDefault();

        const response = await userService.registerNewsletter(
            event.target.email.value
        );
        setEmailNews('');
        if (response.error) {
            if (response.data.message === "El email ya existe") {
                setOpenAlert({
                message:
                    "El correo electrónico ya se encuentra registrado en la lista de newsletter",
                type: "error",
                open: true,
                });
            } else {
                setOpenAlert({
                message:
                    "Hubo un problema al intentar registrar el correo electrónico a la lista de newsletter",
                type: "error",
                open: true,
                });
            }
        } else {
            setOpenAlert({
                message:
                "El correo electrónico se registró con éxito a la lista de newsletter",
                type: "success",
                open: true,
            });
        }
    }

    const getCats = async ( ) => {
        const response = await categoryServices.getCategoryTree();
        let arrayCats = response.tree;
        let arrayAux = [];
        for(let i = 0; i < arrayCats.length; i++) {
          if(arrayCats[i].cat_categoria_id == "112"){
            const arraySubcatsDefault = arrayCats[i].hijos;
            setSubCatsDefault(arraySubcatsDefault);
            for(let i = 0; i< arraySubcatsDefault.length; i++){
              if(arraySubcatsDefault[i].cat_cmm_estatus_id == 1000010){
                let idCategoria = `${arraySubcatsDefault[i].cat_categoria_id}`;
                let page = 0;
                let pagesize = 12;
                let orderBy = "fecha lanzamienta";
                let orderByType = "asc";
                let idSocioNegocio = JSON.parse(localStorage.getItem("client"))? JSON.parse(localStorage.getItem("client")).listaSnbyid[0].sn_socios_negocio_id : process.env.REACT_APP_API_USER_B2B;
                const response = await categoryServices.getCategoryData(
                  idCategoria,
                  page,
                  pagesize,
                  orderBy,
                  orderByType,
                  idSocioNegocio
                );
                const cantidadPrductos = response.mainConsultaProductos.count;
                arrayAux.push({
                  idSubcat: arraySubcatsDefault[i].cat_categoria_id,
                  cantidadP: cantidadPrductos,
                })
              }
            }
          }
        }
        setContadores(arrayAux);
        setCategorysArray(arrayCats);
    };

    const getSBB = async () => {
        let _sbb = await homeServices.loadSection("SECONDARY_BANNERS_BOTTOM");
        let _temp_a = [];
        for (let i = 0; i < _sbb.archivos_main.length; i++) {
          let _a = {
            id: _sbb.archivos_main[i].adi_archivo_de_inicio_id,
            img:
              API_URL +
              _sbb.archivos_main[i].adi_ruta_archivo.replace("./public", ""),
            link: _sbb.archivos_main[i].adi_url,
          };
          _temp_a.push(_a);
        }
        globalData._currentValue.mid_banners.banners = _temp_a;
    };

    const getBrands = async () => {
        const brands = await homeServices.loadSection("BRANDS_BANNER");
        const _temp_a = [];
        for (let i = 0; i < brands.archivos_main.length; i++) {
            const _a = {
                id: brands.archivos_main[i].adi_archivo_de_inicio_id,
                img:
                    API_URL +
                    brands.archivos_main[i].adi_ruta_archivo.replace("./public", ""),
                link: brands.archivos_main[i].adi_url,
            };
            _temp_a.push(_a);
        }
        globalData._currentValue.mid_brands.options = _temp_a;
    };

    const getCollections = async () => {
        const dataCollection1 = await homeServices.getListadoColecciones();
        setCollectionDataFirts(dataCollection1);
        const dataCollection2 = await homeServices.getListadoColecciones2();
        setCollectionDataSecond(dataCollection2);
        const dataCollection3 = await homeServices.getListadoColecciones3();
        const primerosOcho = {
            ...dataCollection3,
            productosColeccion: dataCollection3?.productosColeccion?.slice(0, 10)
        };
        setCollectionDataThird(primerosOcho);
    }
    
    useEffect(() => {
        const withTotal = window.innerWidth;
        if(withTotal > 0) {
            setAnchoTotalGrid(((withTotal / 12) * 3) - 6);
        }

    }, [window.innerWidth]);

    useEffect(() => {
        if(isMobile) {
            getMainSection();
        } else {
            getMainSection();
        }
    }, [isMobile])

    const dataProducts= async () =>{
      //  const data = await homeServices.getListadoProductosPromociones(10);
       // setProductsData(data)
    }
    
    useEffect(() => {
        getMainSection();
        getCats();
        getCollections();
        getSBB();
        getBrands();
       // dataProducts();
    }, []);

    return (
        <>
        <Container fixed paddingBottom={2}>
            <Box width="100%">
                <Loader />
                <Grid container sx={{ paddingTop: 2, paddingBottom: 4 }}>
                    <Grid item md={3} lg={3} className={classes.menuContainer}>
                        <CategoriesMenu anchoTotalGrid={anchoTotalGrid} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={9}
                        lg={9}
                        sx={{
                            paddingLeft: 3,
                            paddingRight: 3,
                        }}
                    >
                        <SectionHomeMainBannerNew
                            mainBanners={globalData._currentValue.mainBanners}
                        />
                    </Grid>
                    {collectionDataFirts?.productosColeccion &&
                    <Grid item xs={12}>
                        <Container maxWidth="xl" sx={{ paddingTop: '1.5rem' }}>
                            <CollectionComponent
                                collectionData={collectionDataFirts}
                                isMobile={isMobile}
                            />
                        </Container>
                    </Grid>}

                  
                    <Grid item xs={12}>
                        <Container maxWidth="xl" sx={{ paddingTop: '1.5rem' }}>
                            {window.innerWidth < 1079
                                ? <SectionSomosMayoristasMob
                                    arrayCats={categorysArray}
                                />
                                : <SectionSomosMayoristas
                                    arrayCats={categorysArray}
                                    subCatsDefault={subCatsDefault}
                                    arrayCont={contadores}
                                />
                            }
                        </Container>
                    </Grid>

                    <Grid item xs={12}>
                        <Container maxWidth="xl" sx={{ paddingTop: '1.5rem' }}>
                            <SectionHomeMidCarousel
                                globalData={globalData._currentValue.mid_brands}
                                isMobile={isMobile}
                            />
                        </Container>
                    </Grid>


                    
                    {collectionDataSecond?.productosColeccion &&
                    <Grid item xs={12}>
                        <Container maxWidth="xl" sx={{ paddingTop: '1.5rem' }}>
                            <CollectionComponent
                                collectionData={collectionDataSecond}
                                isMobile={isMobile}
                            />
                        </Container>
                    </Grid>}
                    <Grid item xs={12}>
                        <Container maxWidth="xl" sx={{ paddingTop: '1.5rem' }}>
                            <SectionHomeMidBanners
                                globalData={globalData._currentValue.mid_banners}
                                isMobile={isMobile}
                            />
                        </Container>
                    </Grid>
                  
                    {collectionDataThird?.productosColeccion &&
                    <Grid item xs={12}>
                        <Container maxWidth="xl" sx={{ paddingTop: '1.5rem' }}>
                            <LastExistenceComponent
                                collectionData={collectionDataThird}
                                isMobile={isMobile}
                            />
                        </Container>
                    </Grid>}
                </Grid>
            </Box>
        </Container>

            <Box
                sx={{
                    backgroundColor: '#e5ecec',
                    marginBottom: '-16px',
                    padding: '15px 0px'
                }}
            >
                <Container>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid item xs={12} sm={12}>
                                    <Typography
                                        variant="h4"
                                        sx={{ textAlign: 'center', color: '#0b3196' }}
                                    >
                                        ÚNETE A NUESTRO
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography
                                        variant="h3"
                                        sx={{ fontWeight: 800, textAlign: 'center', color: '#0b3196' }}
                                    >
                                        NEWSLETTER
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h5"
                                        gutterBottom
                                        sx={{ textAlign: 'center', color: '#0b3196' }}
                                    >
                                        Entérate de nuestras noticias, promociones y más.
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} sm={8}>
                                    <form onSubmit={(e) => registerNewsletter(e)}>
                                        <TextField
                                            label="Escribe tu mail"
                                            type="email"
                                            name="email"
                                            value={emailNews}
                                            onChange={e => setEmailNews(e.target.value)}
                                            variant="outlined"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '35px',
                                                    color: '#63e22b',
                                                    '& fieldset': {
                                                        borderColor: '#d7d7d7 !important',
                                                        borderWidth: '4px !important',
                                                    },
                                                },
                                                '& label': {
                                                    color: '#63e22b',
                                                    fontWeight: 800,
                                                },
                                                '&:focus-within label': {
                                                    color: '#63e22b',
                                                    fontWeight: 800,
                                                },
                                            }}
                                            fullWidth
                                        />
                                    </form>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default HomeViewNew