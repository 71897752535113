import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import iconDelete from "assets/img/Dielsa/Icon_Eliminar.svg";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { getSlug } from "helpers/getSlug";

const useStyles = makeStyles((styles) => ({
  hide: {
    display: "none !important",
  },
  /// Cart
  cartDisplayFullDiv: {
    width: "415px",
    height: "100vh",
    display: "block",
    position: "fixed",
    zIndex: "1002",
    backgroundColor: "#ffffff",
    float: "right",
    right: 0,
    top: 0,
    boxShadow: "-5px 0px 10px 1px #aaaaaa",
    overflowY: "scroll",
    [styles.breakpoints.down("sm")]: {
      width: "85%",
    },
  },
  cartHeaderDiv: {
    width: "100%",
    height: "10vh",
    backgroundColor: "#0b3196",
  },
  cartHeaderBodyDiv: {
    width: "100%",
    height: "62vh",
    backgroundColor: "#ffffff",
    overflowY: "scroll",
  },
  cartFooterBodyDiv: {
    width: "100%",
    height: "18vh",
    backgroundColor: "#ffffff",
    boxShadow: "0 -5px 10px -10px #333",
    marginTop: "5px",
  },
  /// Header
  cartClose: {
    width: "100%",
    textAlign: "right",
    color: "#ffffff",
    paddingRight: "10px",
    paddingTop: "0px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  cartCloseTxt: {
    color: "#ffffff",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "18px",
    display: "inline-block",
    verticalAlign: "-webkit-baseline-middle",
    marginRight: "15px",
    fontWeight: "200",
  },
  cartCloseX: {
    color: "#ffffff",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
    display: "inline-block",
    verticalAlign: "top",
    fontWeight: "200",
  },
  cartTitle: {
    width: "100%",
    textAlign: "center",
    color: "#ffffff",
    fontSize: "24px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
  },
  /// Body
  productDiv: {
    borderBottom: "2px solid #eaeaea",
  },
  imgDiv: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "top",
    paddingTop: "14px",
    paddingLeft: "8px",
    paddingRight: "5px",
  },
  detailsDiv: {
    width: "70%",
    display: "inline-block",
    verticalAlign: "top",
    paddingTop: "15px",
    paddingRight: "7px",
  },
  productName: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    fontSize: "15px",
    height: "64px",
    overflow: "hidden",
    textAlign: "justify",
    color: "#000000",
    cursor: "pointer",
  },
  preciosDiv: {
    marginTop: "10px",
    marginBottom: "5px",
  },
  precioLista: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "300",
    fontSize: "18px",
    textAlign: "justify",
    color: "#000000",
  },
  precioUnidad: {
    color: "#0b3196",
    marginTop: 0,
    fontSize: "20px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    display: "inline-block",
  },
  precioListaCross: {
    color: "#979797",
    fontFamily: "'Centrale Sans Thin'",
    textDecoration: "line-through",
    marginBottom: 0,
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: 1,
    display: "inline-block",
  },
  precioUnidadCross: {
    color: "#0b3196",
    textDecoration: "line-through",
    marginTop: 0,
    fontSize: "16px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    display: "inline-block",
  },
  precioPromo: {
    color: "#E41212",
    marginTop: 0,
    fontSize: "20px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    display: "inline-block",
  },
  descriptionPrice: {
    color: "#000000",
    marginTop: 0,
    fontSize: 14,
    fontFamily: "'Centrale Sans Light'",
    fontWeight: "400",
    display: "inline-block",
    paddingLeft: 10,
    width: 125,
  },
  stockDiv: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  productoStock: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
    fontSize: "18px",
    color: " #40DF00",
  },
  productoDisponible: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
    fontSize: "18px",
    color: "#40df00",
  },
  productoNoDisponible: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
    fontSize: "18px",
    color: "#9b9b9b",
  },
  productoSubtotalDiv: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  productoSubtotal: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "600",
    fontSize: "20px",
    color: "#000000",
    display: "inline-block",
  },
  deleteDiv: {
    width: "25%",
    display: "inline-block",
  },
  btnDel: {
    marginLeft: "-15px",
  },
  inputDiv: {
    width: "75%",
    display: "inline-block",
    textAlign: "right",
  },
  btnInput: {
    backgroundColor: "#ffffff",
    color: "#0b3196",
    border: "2px solid #eaeaea",
    fontWeight: "800",
    backgroundColor: "#ffffff",
    fontSize: "16px",
    padding: "0px",
    maxHeight: "40px",
    width: "35px",
    minWidth: "auto",
    display: "inline-block",
    verticalAlign: "top",
    lineHeight: "36px",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
  },
  customIconDelete: {
    width: "25px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  inpCant: {
    width: "64px",
    display: "inline-block",
    verticalAlign: "top",
    textAlign: "center",
  },
  /// Footer
  footerCartTextDiv: {
    width: "50%",
    display: "inline-block",
    textAlign: "left",
    paddingLeft: "15px",
  },
  footerCartAmountDiv: {
    width: "50%",
    display: "inline-block",
    textAlign: "right",
    paddingRight: "15px",
  },
  subTotalCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    fontSize: "16px",
    color: "#000000",
  },
  subTotalAmountCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    fontSize: "16px",
    color: "#000000",
  },
  totalCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "800",
    fontSize: "16px",
    color: "#000000",
  },
  totalAmountCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "800",
    fontSize: "16px",
    color: "#000000",
  },
  btnDiv: {
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "15px",
  },
  btn: {
    width: "100%",
    backgroundColor: "#ffffff",
    color: "#0b3196",
    border: "2px solid #0b3196",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
  },
  /// Empty cart
  emptyCartDiv: {
    width: "100%",
    paddingTop: "50px",
    textAlign: "center",
  },
  emptyCartTxt: {
    fontSize: "24px",
    color: "#000000",
    padding: 5,
  },
  /// Img cart
  imgCart: {
    width: "100%",
    cursor: "pointer",
  },
  title: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    display: "inline-block",
  },
  text: {
    color: "#9B9B9B",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    display: "inline-block",
    marginLeft: "5px",
  },
}));

const ItemLocalMiniCart = ({
  product,
  removeFromCart,
  handleChangeQuantity,
  increaseQuantity,
  decreaseQuantity,
}) => {
  const classes = useStyles();

 
  return (
    <div className={classes.productDiv}>
      <div className={classes.imgDiv}>
        <Link 
      
        to={`/producto/detalle/${getSlug(product.nombre)}/${getSlug(product.nombre_extranjero)}?prod=${product.productoId}&sku=${product.skuId}`}

        >
          {product.imagen ? (
            <img className={classes.imgCart} src={product.imagen} />
          ) : (
            <img
              className={classes.imgCart}
              src="http://wws.coproduct.pa/wp-content/plugins/wordpress-ecommerce/marketpress-includes/images/default-product.png"
            />
          )}
        </Link>
      </div>
      <div className={classes.detailsDiv}>
        <Link 
        to={`/producto/detalle/${getSlug(product.nombre)}/${getSlug(product.nombre_extranjero)}?prod=${product.productoId}&sku=${product.skuId}`}
        >
          <Typography className={classes.productName}>
            {product.nombre}
          </Typography>
        </Link>
        <Typography className={classes.title}>SKU:</Typography>
        <Typography className={classes.text}>
          {product.nombre_extranjero}
        </Typography>
        <div className={classes.stockDiv}>
          <Typography className={classes.productoStock}>
            En stock ({product.stock})
          </Typography>
        </div>
        <div>
          <div className={classes.deleteDiv}>
            <IconButton
              aria-label="delete"
              className={classes.btnDel}
              onClick={() => {
                removeFromCart(product.sku);
              }}
            >
              <img src={iconDelete} className={classes.customIconDelete} />
            </IconButton>
          </div>
          <div className={classes.inputDiv}>
            <Button
              variant="contained"
              className={classes.btnInput}
              disableElevation
              onClick={() => {
                decreaseQuantity(product.sku);
              }}
            >
              -
            </Button>
            <TextField
              id={"inpCant" + product.productoId}
              value={product.cantidad}
              onChange={(ev) => {
                handleChangeQuantity(product.sku, ev.target.value);
              }}
              size="small"
              className={classes.inpCant + " inp-center"}
              variant="outlined"
            />
            <Button
              variant="contained"
              className={classes.btnInput}
              disableElevation
              onClick={() => {
                increaseQuantity(product.sku);
              }}
            >
              +
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemLocalMiniCart;
