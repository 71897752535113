import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { getSlug } from "helpers/getSlug";

const useStyles = makeStyles((styles) => ({
  title: {
    height: "60px",
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "20px",
    fontStyle: "italic",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "24px",
    textAlign: "justify",
  },
  titleSmall: {
    height: "60px",
    opacity: "0.95",
    color: "#000000",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "16px",
    fontStyle: "italic",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "16px",
    textTransform: "capitalize",
    lineBreak: "anywhere",
  },
  container: {
    width: "95%",
    height: 72,
    overflow: "hidden",
  },
  linkBtn: {
    cursor: "pointer",
  },
}));

export default function SectionProductName(props) {
  const classes = useStyles();

  return (
    <Link
    
      to={`/producto/detalle/${getSlug(props.productDetails.nombre)}/${getSlug(props.productDetails.nombre_extranjero)}?prod=${props.productDetails.productoId}&sku=${props.productDetails.skuId}`}

    >
      <div className={classes.linkBtn} title={props.productDetails.nombre}>
        <div className={classes.container}>
          <span
            className={
              props.gridView === false ? classes.title : classes.titleSmall
            }
          >
            {props.productDetails.nombre}
          </span>
        </div>
      </div>
    </Link>
  );
}
