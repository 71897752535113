import React, { useState, useEffect } from "react";
import "assets/custom/Scrollbar.css";
import "assets/custom/ProductInput.css";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fade from '@mui/material/Fade';
import IconButton from "@material-ui/core/IconButton";
import toast from "react-hot-toast";
import ItemLocalMiniCart from "components/ItemLocalMiniCart";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from "hooks/useAuth";
import { getPriceSapFormat } from "helpers/formatPrice";
const useStyles = makeStyles((styles) => ({
  hide: {
    display: "none !important",
  },
  /// Cart
  cartDisplayFullDiv1: {
    width: "100%",
    height: "100vh",
    display: "block",
    position: "fixed",
    zIndex: "999",
    backdropFilter: 'blur(7px)',
    backgroundColor: '#0000000f',
    float: "right",
    right: 0,
    top: 0,
    boxShadow: "-5px 0px 10px 1px #aaaaaa",
  },
  cartDisplayFullDiv: {
    width: "415px",
    height: "100vh",
    display: "block",
    position: "fixed",
    zIndex: "1002",
    backgroundColor: "#ffffff",
    float: "right",
    right: 0,
    top: 0,
    boxShadow: "-5px 0px 10px 1px #aaaaaa",
    overflowY: "scroll",
    [styles.breakpoints.down("sm")]: {
      width: "85%",
    },
  },
  cartHeaderDiv: {
    width: "100%",
    height: "12vh",
    backgroundColor: "#0b3196",
  },
  cartHeaderBodyDiv: {
    width: "100%",
    backgroundColor: "#ffffff",
    overflowY: "scroll",
    height: "72vh",
  },
  cartFooterBodyDiv: {
    width: "100%",
    height: "10vh",
    backgroundColor: "#ffffff",
    boxShadow: "0 -5px 10px -10px #333",
    marginTop: "5px",
    display: "flex",
    alignItems: "end",
  },
  /// Header
  cartClose: {
    width: "100%",
    textAlign: "right",
    color: "#ffffff",
    paddingRight: "10px",
    paddingTop: "0px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  cartCloseTxt: {
    color: "#ffffff",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "18px",
    display: "inline-block",
    verticalAlign: "-webkit-baseline-middle",
    marginRight: "15px",
    fontWeight: "200",
  },
  cartCloseX: {
    color: "#ffffff",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "24px",
    display: "inline-block",
    verticalAlign: "top",
    fontWeight: "200",
  },
  cartTitle: {
    width: "100%",
    textAlign: "center",
    color: "#ffffff",
    fontSize: "24px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
  },
  /// Body
  productDiv: {
    borderBottom: "2px solid #eaeaea",
  },
  imgDiv: {
    width: "30%",
    display: "inline-block",
    verticalAlign: "top",
    paddingTop: "14px",
    paddingLeft: "8px",
    paddingRight: "5px",
  },
  detailsDiv: {
    width: "70%",
    display: "inline-block",
    verticalAlign: "top",
    paddingTop: "15px",
    paddingRight: "7px",
  },
  productName: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    fontSize: "15px",
    height: "60px",
    overflow: "hidden",
    textAlign: "justify",
    color: "#000000",
    cursor: "pointer",
  },
  preciosDiv: {
    marginTop: "10px",
    marginBottom: "5px",
  },
  precioLista: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "300",
    fontSize: "18px",
    textAlign: "justify",
    color: "#000000",
  },
  precioUnidad: {
    color: "#0b3196",
    marginTop: 0,
    fontSize: "20px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    display: "inline-block",
  },
  precioListaCross: {
    color: "#979797",
    fontFamily: "'Centrale Sans Thin'",
    textDecoration: "line-through",
    marginBottom: 0,
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: 1,
    display: "inline-block",
  },
  precioUnidadCross: {
    color: "#0b3196",
    textDecoration: "line-through",
    marginTop: 0,
    fontSize: "16px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    display: "inline-block",
  },
  precioPromo: {
    color: "#E41212",
    marginTop: 0,
    fontSize: "20px",
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    display: "inline-block",
  },
  descriptionPrice: {
    color: "#000000",
    marginTop: 0,
    fontSize: 14,
    fontFamily: "'Centrale Sans Light'",
    fontWeight: "400",
    display: "inline-block",
    paddingLeft: 10,
    width: 125,
  },
  stockDiv: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  productoStock: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
    fontSize: "18px",
    color: "#0b3196",
  },
  productoDisponible: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
    fontSize: "18px",
    color: "#40df00",
  },
  productoNoDisponible: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "400",
    fontSize: "18px",
    color: "#9b9b9b",
  },
  productoSubtotalDiv: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  productoSubtotal: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "600",
    fontSize: "20px",
    color: "#000000",
    display: "inline-block",
  },
  deleteDiv: {
    width: "25%",
    display: "inline-block",
  },
  btnDel: {
    marginLeft: "-15px",
  },
  inputDiv: {
    width: "75%",
    display: "inline-block",
    textAlign: "right",
  },
  btnInput: {
    backgroundColor: "#ffffff",
    color: "#0b3196",
    border: "2px solid #eaeaea",
    fontWeight: "800",
    backgroundColor: "#ffffff",
    fontSize: "36px",
    padding: "0px",
    maxHeight: "40px",
    width: "42px",
    minWidth: "auto",
    display: "inline-block",
    verticalAlign: "top",
    lineHeight: "36px",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
  },
  customIconDelete: {
    width: "36px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  inpCant: {
    width: "64px",
    display: "inline-block",
    verticalAlign: "top",
    textAlign: "center",
  },
  /// Footer
  footerCartTextDiv: {
    width: "50%",
    display: "inline-block",
    textAlign: "left",
    paddingLeft: "15px",
  },
  footerCartAmountDiv: {
    width: "50%",
    display: "inline-block",
    textAlign: "right",
    paddingRight: "15px",
  },
  subTotalCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    fontSize: "16px",
    color: "#000000",
  },
  subTotalAmountCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "500",
    fontSize: "16px",
    color: "#000000",
  },
  totalCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "800",
    fontSize: "16px",
    color: "#000000",
  },
  totalAmountCart: {
    fontFamily: "'Centrale Sans Medium'",
    fontWeight: "800",
    fontSize: "16px",
    color: "#000000",
  },
  btnDiv: {
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "15px",
  },
  btn: {
    width: "100%",
    backgroundColor: "#ffffff",
    color: "#0b3196",
    border: "2px solid #0b3196",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#0b3196",
      color: "#ffffff",
    },
  },
  /// Empty cart
  emptyCartDiv: {
    width: "100%",
    paddingTop: "50px",
    textAlign: "center",
  },
  emptyCartTxt: {
    fontSize: "24px",
    color: "#000000",
    padding: 5,
  },
  /// Img cart
  imgCart: {
    width: "100%",
    cursor: "pointer",
  },
  title: {
    color: "#0B3196",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    display: "inline-block",
  },
  text: {
    color: "#9B9B9B",
    fontFamily: "'Centrale Sans Medium'",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    display: "inline-block",
    marginLeft: "5px",
  },
}));

export default function LocalCartHeader(props) {
  const {carritoItems,setCarritoItems} = useAuth();
  const classes = useStyles();
  const [cart, setCart] = useState([]);

  useEffect(() => {
    if(props.showCar){
      getCart();
    }
  }, [props.showCar]);

  const getCart = () => {
    const localCart = JSON.parse(localStorage.getItem("myCart"));
    const cartValues = localCart ? localCart : [];
    setCart(cartValues);
  };

  const setMyCartNewValues = (cart) => {
    localStorage.setItem("myCart", JSON.stringify(cart));
    getCart();
    props.getCarrito();
  };

  const removeFromCart = async (sku) => {
    const localCart = JSON.parse(localStorage.getItem("myCart"));
    let currentCart = localCart.filter((item) => item.sku != sku);
    setMyCartNewValues(currentCart);
    setCarritoItems(carritoItems-1);
  };

  const removeFromCartClean = async (sku) => {
    const localCart = JSON.parse(localStorage.getItem("myCart"));
    let currentCart = localCart.filter((item) => item.sku != sku);
    setMyCartNewValues(currentCart);
    //setCarritoItems(carritoItems-1);
  };

  const cleanCart = () => {
    const localCart = JSON.parse(localStorage.getItem("myCart"));
    if(localCart.length > 0){
      for(let i = 0; i < localCart.length; i++){
        removeFromCartClean(localCart[i].sku);
      }
      setCarritoItems(0)
    }
  };

  const handleChangeQuantity = async (sku, cantidad) => {
    if (cantidad > 0) {
      const currentCart = JSON.parse(localStorage.getItem("myCart"));
      const getItem = currentCart.find((item) => item.sku === sku);
      const itemIndex = currentCart.indexOf(getItem);
      currentCart[itemIndex].cantidad = cantidad;
      setMyCartNewValues(currentCart);
    } else {
      toast.error("Cantidad debe ser mayor a 0");
    }
  };

  const increaseQuantity = async (sku) => {
    const currentCart = JSON.parse(localStorage.getItem("myCart"));
    const getItem = currentCart.find((item) => item.sku === sku);
    const itemIndex = currentCart.indexOf(getItem);
    let currentQuantity = parseInt(currentCart[itemIndex].cantidad);
    currentQuantity++;
    currentCart[itemIndex].cantidad = currentQuantity;
    setMyCartNewValues(currentCart);
  };

  const decreaseQuantity = async (sku) => {
    const currentCart = JSON.parse(localStorage.getItem("myCart"));
    const getItem = currentCart.find((item) => item.sku === sku);
    const itemIndex = currentCart.indexOf(getItem);
    let currentQuantity = parseInt(currentCart[itemIndex].cantidad);
    if (currentQuantity > 1) {
      currentQuantity--;
      currentCart[itemIndex].cantidad = currentQuantity;
      setMyCartNewValues(currentCart);
    }
  };

  return (
    <>
      <Fade in={props.showCar} timeout={{ enter: 100, exit: 100 }}>
        <div>
          <Fade in={props.showCar} timeout={{ enter: 1800, exit: 1800 }}>
            <div
              className={classes.cartDisplayFullDiv1}
              onClick={() => {
                props.closeCartDiv();
              }}
            />
          </Fade>
          <div className={classes.cartDisplayFullDiv}>
            <div className={classes.cartHeaderDiv}>
              <div
                className={classes.cartClose}
              >
                <IconButton aria-label="close" onClick={() => props.closeCartDiv()}>
                  <CloseIcon sx={{ color: '#fff' }} />
                </IconButton>
              </div>
              <Typography className={classes.cartTitle} sx={{ paddingBottom: 6 }}>Carrito</Typography>
            </div>
            <div className={classes.cartHeaderBodyDiv}>
              {cart &&
                cart.length > 0 &&
                cart.map((product, index) => ( 
                  <ItemLocalMiniCart
                    increaseQuantity={increaseQuantity}
                    decreaseQuantity={decreaseQuantity}
                    handleChangeQuantity={handleChangeQuantity}
                    removeFromCart={removeFromCart}
                    key={`cart-item-id-${index}`}
                    product={product}
                  />
                ))}
              {cart && cart.length === 0 && (
                <div
                  className={classes.emptyCartDiv}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: 60,
                    paddingBottom: 60,
                  }}
                >
                  <Typography className={classes.emptyCartTxt}>
                    El carrito de compras se encuentra vacío
                  </Typography>
                  <div style={{ paddingTop: '60px' }}>
                    <ShoppingCartIcon sx={{ fontSize: 120 }} />
                  </div>
                </div>
              )}
            </div>
            {cart !== null && cart !== undefined ? (
              <div className={classes.cartFooterBodyDiv}>
                <div className={classes.btnDiv}>
                  <Link to={`/quotes/request`}>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      disableElevation
                      onClick={() => {
                        props.closeCartDiv();
                      }}
                    >
                      Solicitar Cotización
                    </Button>
                  </Link>
                
                    <Button
                      style={{marginTop: 15, marginBottom: 15}}
                      variant="contained"
                      className={classes.btn}
                      disableElevation
                      onClick={cleanCart}
                    >
                      Limpiar Carrito
                    </Button>
                
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Fade>
    </>
  );
}
