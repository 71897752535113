import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { API_URL } from "_services/api.url.back";
// @material-ui/icons
// core components
// sections for this page

import { Link } from "react-router-dom";

import SectionProductName from "./Sections/SectionProductName.js";
import SectionProductBrand from "./Sections/SectionProductBrand.js";
import SectionProductSKU from "./Sections/SectionProductSKU.js";
import SectionProductBullets from "./Sections/SectionProductBullets.js";
import SectionProductStock from "./Sections/SectionProductStock.js";
import SectionProductButtonAddCart from "./Sections/SectionProductButtonAddCart.js";
import SectionProductPrice from "./Sections/SectionProductPrice.js";

import SectionProductClientRating from "./Sections/SectionProductRating.js";

import imgFav from "assets/img/Dielsa/Icon_Favoritos.svg";

import { productsService } from "../../../_services/products.services";
import { Button } from "@mui/material";
import { useAuth } from "hooks/useAuth.js";
import { getSlug } from "helpers/getSlug";

const useStyles  = makeStyles((styles) => ({
  hide: {
    display: "none !important",
  },
  mainBackground: {
    backgroundColor: "#FFF",
  },
  imageDiv: {
    width: "100%",
    paddingLeft: "1%",
    paddingRight: "1%",
    display: "inline-block",
    verticalAlign: "top",
    textAlign: "center",
    height: "280px",
  },
  image: {
    maxWidth: "100%",
    height: "250px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  detailsDiv: {
    width: "100%",
    display: "inline-block",
  },
  container: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 15,
    marginBottom: 15,
    padding: 5,
    width: "25%",
    display: "inline-block",
    verticalAlign: "top",
    [styles.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    [styles.breakpoints.up("xl")]: {
      width: "20%"
    },
  },
  divButton: {
    width: "100%",
    display: "inline-block",
    verticalAlign: "top",
    borderLeft: "1px solid #eaeaea",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  divdivButtonGrid: {
    width: "100%",
    display: "inline-block",
    verticalAlign: "top",
    marginTop: "15px",
    marginBottom: "15px",
  },
  imgDiv: {
    width: "100%",
    textAlign: "right",
  },
  imgF: {
    width: "28px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  pointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  discountFlag: {
    width: "130px",
    backgroundColor: "#E41212",
    height: "24px",
    display: "inline-block",
    float: "left",
    verticalAlign: "top",
    fontFamily: "'Centrale Sans Regular Regular Italic'",
    color: "#FFFFFF",
    fontSize: "14px",
    textAlign: "center",
    borderRadius: "2px",
  },
  banderaDescuento: {
    width: 130,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    fontSize: 14,
    lineHeight: "14px",
    color: "#FFF",
    backgroundColor: "#E41212",
    borderRadius: "2px",
    fontFamily: "'Centrale Sans Medium'",
    marginTop: "5px",
    fontStyle: "italic",
    fontWeight: 600,
  },
  banderaDescuentoVacia: {
    height: 21,
    marginTop: -5,
    marginBottom: 10,
  },
}));


export default function CategoryProductSmall(props) {
  const classes = useStyles(props.Carrusel);
  const {isLogged}= useAuth()
  
  const [discount, setDiscount] = useState("");
  const [hide, setHide] = useState(classes.hide);
  const [descuento, setDescuento] = useState(null);
 

  useEffect(() => {
    setFlags();
    getDescuento();
  }, []);

  const getDescuento = () => {
    //const res1 = props.product.precioMenosDescuento * 100;
    //const res2 = res1 / props.product.DescuentoDielsaFijo;
    //const result = Math.round((res2 - 100) * 100) / 100;
    // const result = (()/)-100;
    //setDescuento(result);

    if (props.product.tipoPromocionFinal === "Porcentaje") {
      if (props.product.precioPromocionDielsaBool) {
        if (props.product.DescuentoDielsaFijo > 0) {
          setDescuento(
            (
              100 -
              (props.product.precioFinal * 100) / props.product.precioLista
            ).toFixed()
          );
        }
      } else {
        if (props.product.precioMenosDescuento < props.product.precioLista) {
          setDescuento(
            (
              100 -
              (props.product.precioMenosDescuento * 100) /
                props.product.precioLista
            ).toFixed()
          );
        }
      }
    }
  };

  const setFlags = () => {
    let _pL = props.product.priceList;
    let _pC = props.product.priceClient;
    let _ds = 0;
    if (_pL !== _pC && _pL !== 0) {
      _ds = (1 - parseInt(_pC) / parseInt(_pL)) * 100;
    }
    if (_ds !== 0) {
      /*
            setHide(null);
            setDiscount("-" + _ds + "% Descuento");
            */
    }
  };

  const addToFav = async () => {
    let _r = await productsService.addProductoToWishlist(
      props.product.idSKU,
      "Lista Basica",
      JSON.parse(localStorage.getItem("user")).usuario
        .snu_sn_socio_de_negocio_id
    );
  };


  return (
    <div className={classes.container}>
      <div className={classes.imageDiv}>
        <div className={classes.imgDiv}>
          {/* <div className={classes.discountFlag + " " + hide}>{discount}</div> */}
          {props.product.tipoPromocionFinal === "Porcentaje" && (
            props.product.precioPromocionDielsaBool && props.product.DescuentoDielsaFijo > 0
            ? <div className={classes.banderaDescuento}>
                <span>
                  {(100 - (props.product.precioFinal * 100) / props.product.precioLista).toFixed()}
                </span>
                <span> % Descuento</span>
              </div>
            : props.product.precioMenosDescuento < props.product.precioLista &&
              <div className={classes.banderaDescuento}>
                <span>
                  {(100 - (props.product.precioMenosDescuento * 100) / props.product.precioLista).toFixed()}
                </span>
                <span> % Descuento</span>
              </div>
          )}
          {/* <img src={imgFav} className={classes.imgF} onClick={() => { addToFav(); }}/> */}
        </div>
        {props.product?.imagen && (
          <Link
            to={`/producto/detalle/${getSlug(props.product.nombre)}/${getSlug(props.product.nombre_extranjero)}?prod=${props.product.productoId}&sku=${props.product.skuId}`}
            // history.push(`/producto/detalle/${getSlug(link.prod_nombre)}/${getSlug(link.prod_nombre_extranjero)}?prod=${link.productoPadreId}&sku=${link.prod_producto_id}`)
          >
            <img className={classes.image} src={props.product.imagen} />
          </Link>
        )}
      </div>
      <div className={classes.detailsDiv}>
        <SectionProductBrand productDetails={props.product} />
        <Link
           to={`/producto/detalle/${getSlug(props.product.nombre)}/${getSlug(props.product.nombre_extranjero)}?prod=${props.product.productoId}&sku=${props.product.skuId}`}
        >
          <SectionProductName
            productDetails={props.product}
            gridView={props.gridView}
            className={classes.pointer}
          />
        </Link>
        <SectionProductClientRating
          className={classes.space}
          productDetails={props.product}
          gridView={props.gridView}
        />
        <SectionProductSKU
          productDetails={props.product}
          gridView={props.gridView}
        />
        {props.gridView === false ? (
          <SectionProductBullets productDetails={props.product} />
        ) : (
          ""
        )}
      </div>
      <div
        className={
          props.gridView === false
            ? classes.divButton
            : classes.divdivButtonGrid
        }
      >
        {JSON.parse(localStorage.getItem("client")) !== undefined &&
        JSON.parse(localStorage.getItem("client")) !== null ? (
          <SectionProductStock
            productDetails={props.product}
            gridView={props.gridView}
          />
        ) : (
          ""
        )}
        {/* {
                    (JSON.parse(localStorage.getItem("client")) !== undefined && JSON.parse(localStorage.getItem("client")) !== null && JSON.parse(localStorage.getItem("client")).listaSnbyid[0] !== undefined) ? */}

         
         { isLogged&&<SectionProductPrice
            productDetails={props.product}
            gridView={props.gridView}
          />}
         {props.changeFuction
        ? <Button
          sx={{ marginTop: 2, backgroundColor: '#0C2D9C' }}
          variant="contained"
          onClick={() => props.handleFunction(props.product)}
        >
          Añadir a cotización
        </Button>
        : <SectionProductButtonAddCart
          productDetails={props.product}
          gridView={props.gridView}
       
        />
        }
        
    
        
      </div>
    </div>
  );
}
